import React from 'react';
import {Container, Row} from 'react-bootstrap';
import {FaFacebookF as FaFacebook, FaInstagramSquare as FaInstagram, FaAt, FaPhone} from 'react-icons/fa';
import "./Contacts.css";

function Contacts() {

    return (
        <section id="contacts">
            <Container lg={8} className="mx-auto">
                <Row>
                    <div className="">
                        <h2>Contacts</h2>
                        <ul>
                            <li><FaAt size="25px" /> geral@euroboia.com</li>
                            <li><FaPhone size="25px" /> +351 244 767 461</li>
                            <li><FaFacebook size="25px" /> <a href="https://www.facebook.com/euroboia/" target="_blank">facebook.com/euroboia</a></li>
                            <li><FaInstagram size="25px" /> <a href="https://www.instagram.com/euroboia/" target="_blank">instagram.com/euroboia</a></li>
                        </ul>
                    </div>
                </Row>
            </Container>
        </section>
    );
}

export default Contacts;
