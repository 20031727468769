import React from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import {FaFilePdf} from 'react-icons/fa';
import catalogPdf from './catalogo Euroboia_PT 2020_13Fev21.pdf';
import image1 from './images/image1.jpeg';
import image2 from './images/image2.jpeg';
import image3 from './images/image3.jpeg';
import image4 from './images/image4.jpeg';
import image5 from './images/image5.jpeg';
import "./Products.css";

function Products() {

    return (
        <section id="products">

            <Container lg={8} className="mx-auto">
                <Row>
                    <Col md={4} sm={12}><Image className="image mx-auto d-block" src={image1} atl="image 1" fluid/></Col>
                    <Col md={4} sm={12}><Image className="image mx-auto d-block" src={image2} atl="image 2" fluid/></Col>
                    <Col md={4} sm={12}><Image className="image mx-auto d-block" src={image3} atl="image 3" fluid/></Col>
                    <Col md={4} sm={12}><Image className="image mx-auto d-block" src={image4} atl="image 4" fluid/></Col>
                    <Col md={4} sm={12}><Image className="image mx-auto d-block" src={image5} atl="image 5" fluid/></Col>
                </Row>
                <Row>
                    <div className="mt-5">
                        <h2>Products</h2>
                        <p className="lead">
                            Download our <a className="catalog-pdf-link" href={catalogPdf} target="_blank" rel="noopener noreferrer">latest
                            catalog <FaFilePdf size="50px"/></a></p>
                    </div>
                </Row>

            </Container>
        </section>
    );
}

export default Products;
