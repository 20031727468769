import React from "react";
import {Container, Row} from "react-bootstrap";


function About() {

    return (
        <section id="about" className="bg-light">
            <Container lg={8}>
                <Row>
                    <h2>About us</h2>
                    <p className="lead">
                        Euroboia is a Small and Medium Enterprise engaged in the manufacture of plastic products,
                        more specifically, floats and balls.
                        Established in 1992, Euroboia has a vast experience in producing fishing buoys,
                        beach balls, hokey balls and multiple other products.
                        In 2003 changed the commercial name to “Euroboia - Indústria de Artigos de Plástico,
                        Lda.”, and since then exports world wide.</p>
                </Row>
            </Container>
        </section>
    );
}

export default About;