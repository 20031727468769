import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './App.css';
import Header from "./Header";
import Products from "./Products";
import About from "./About";
import AppNav from "./AppNav.js";
import Contacts from "./Contacts";

function App() {


    return (
        <Router>
            <AppNav/>
            <Switch>
                <Route path="/" render={props =>
                    [
                        <Header key="header" name="header"/>,
                        <Products key="products" name="products"/>,
                        <About key="name" name="name"/>,
                        <Contacts key="contacts" name="contacts"/>
                    ]
                }/>
            </Switch>
        </Router>
    );
}

export default App;
