import React from 'react';
import './Header.css';


function Header() {

    return (
        <section id="header" className="bg-light">
            <div className="bg-container"/>
            <div className="container text-center">
                <h1 className="headerTitle">Euroboia</h1>
                <p className="lead">Plastic Industry | Buoys for fishing and fenders </p>
            </div>
        </section>
    );
}

export default Header;
