import React from 'react';
import './AppNav.css';
import {Navbar, Nav} from "react-bootstrap";
import {HashLink} from "react-router-hash-link";

function AppNav() {

    return (
        <Navbar className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" expand="lg">
            <Navbar.Brand className="nav-title">Euroboia</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <li className="nav-item">
                        <HashLink className="nav-link" to="/#header">Home</HashLink>
                    </li>
                    <li className="nav-item">
                        <HashLink className="nav-link" to="/#products">Products</HashLink>
                    </li>
                    <li className="nav-item">
                        <HashLink className="nav-link" to="/#about">About</HashLink>
                    </li>
                    <li className="nav-item">
                        <HashLink className="nav-link" to="/#contacts">Contacts</HashLink>
                    </li>

                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default AppNav;